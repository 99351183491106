import { Link } from "@biblioteksentralen/react";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";

export const MinStatistikkLenke = () => {
  const { t } = useTranslation();

  return (
    <Link as={NextLink} href="/statistikk" variant="plain" position="relative">
      <p>{t("Statistikk")}</p>
    </Link>
  );
};
