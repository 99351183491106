import {
  CordataExpression,
  CordataManifestation,
  CordataTitle,
  CordataWork,
} from "@biblioteksentralen/bmdb-search-client";
import { BibbiData, Publication, SanityKeyed } from "@forrigebok/generatedtypes";
import { nanoid } from "nanoid";
import { sift } from "radash";
import { bibbiAudienceToForrigebokAudienceMap, ForrigebokAudienceAges } from "./audienceAges";
import { ForrigebokWorkTags } from "./tags";
import { normalizeIsbn } from "common/normalizeIsbn";

type ManifestationWithExpression = {
  manifestation: CordataManifestation;
  expression: CordataExpression;
};

export function createBibbiDataFromCordataWork(work: CordataWork): BibbiData {
  const workId = work.id;
  const manifestationsWithExpression =
    work.expressions?.flatMap((expression) =>
      (expression.manifestations ?? []).flatMap((manifestation) => ({ manifestation, expression }))
    ) ?? [];
  const sortedManifestations = sortManifestations(manifestationsWithExpression);
  const agregateManifestationsWithExpression =
    work.expressions?.flatMap((expression) =>
      (expression.aggregateManifestations ?? []).flatMap((manifestation) => ({ manifestation, expression }))
    ) ?? [];
  const sortedAggregateManifestations = sortManifestations(agregateManifestationsWithExpression);
  const publications = sortedManifestations.map(getPublication);
  const partOfPublications = sortedAggregateManifestations.map(getPublication);
  const { title, subtitle } = getTitle(work, sortedManifestations);
  const authors = work.contributors.map((contributor) => contributor.agent.name.nb) ?? [];
  const searchableText = [title, subtitle, ...authors, workId, ...publications.map((pub) => pub.isbn)]
    .filter(Boolean)
    .join(" ");

  const bibbiData: BibbiData = {
    _type: "bibbiData",
    workId,
    title,
    subtitle,
    authors,
    coverImage: getCoverImage(sortedManifestations.length > 0 ? sortedManifestations : sortedAggregateManifestations),
    description: work.summary,
    about: work.subjects.map((subject) => subject.name.nb),
    genre: work.genres.map((genre) => genre.name.nb),
    originalYear: work.workYear?.toString(),
    publications,
    partOfPublications,
    workTypes: work.workTypes.map((type) => type.label.nb),
    audienceAges: getAudienceAges(work),
    updatedAt: new Date().toISOString(),
    searchableText,
    tags: getTags(sortedManifestations),
  };

  if (!bibbiData.title) {
    throw new Error(
      `Det skjedde en feil ved parsing av katalogdata (verksId: ${workId} / isbn: ${bibbiData.publications?.[0]?.isbn})`
    );
  }

  return bibbiData;
}

const getTitle = (
  work: CordataWork,
  sortedManifestations: ManifestationWithExpression[]
): Pick<BibbiData, "title" | "subtitle"> => {
  const norskeManifestasjoner = sortedManifestations.filter(({ expression }) =>
    (["nno", "nob"] as const).some((lang) => expression.languages.map(({ code }) => code).includes(lang))
  );
  const workTitle: CordataTitle | undefined = work.title?.titleType !== "uniform_title_music" ? work.title : undefined;
  const title =
    // Vi foretrekker tittel fra et norsk uttrykk av verket. Hvis det finnes en standard uttrykkstittel er den å
    // foretrekke, ellers viser vi manifestasjonstittel fra en tilfeldig norsk manifestasjon.
    norskeManifestasjoner.find(({ expression }) => expression.title)?.expression.title ??
    norskeManifestasjoner.find(({ manifestation }) => manifestation.title)?.manifestation.title ??
    // Fallbacker til originaltittel / verkstittel hvis det ikke finnes noen norsk utgivelse
    workTitle ??
    // Fallbacker til en tilfeldig utgivelse hvis vi fortsatt ikke har funnet en tittel vi kan bruke
    sortedManifestations.find(({ manifestation }) => manifestation.title)?.manifestation.title;

  if (!title) throw new Error(`Fant ikke tittel for verk (${work.id})`);

  return formatTitle(title);
};

const formatTitle = (title: CordataTitle) => {
  const titleParts = sift([title.mainTitle, title.partNumber, title.partTitle]);
  return { title: titleParts.join(". "), subtitle: formatSubtitle(title.subtitle) };
};

const formatSubtitle = (subtitle?: string) => {
  if (!subtitle) return undefined;
  if (subtitle.toLowerCase() === "roman") return undefined;
  return capitalizeFirstLetter(subtitle);
};

const capitalizeFirstLetter = (str: string) => `${str?.charAt(0).toUpperCase()}${str?.slice(1)}`;

const getCoverImage = (sortedManifestations: ManifestationWithExpression[]): BibbiData["coverImage"] =>
  downscaleBibbiImage(
    sortedManifestations.find((manifestation) => !!manifestation.manifestation.coverImage)?.manifestation.coverImage ??
      undefined
  );

const getAudienceAges = (work: CordataWork): ForrigebokAudienceAges[] => {
  const audienceAges = work.ageGroups
    .map(
      (bibbiAudience) =>
        bibbiAudienceToForrigebokAudienceMap[bibbiAudience.nortargetUri ?? ""] as ForrigebokAudienceAges
    )
    .filter(Boolean);

  return audienceAges?.length ? audienceAges : ["Voksne"]; // Fallbacker til Voksne dersom vi ikke har BMU-ing
};

const getPublication = ({ manifestation, expression }: ManifestationWithExpression): SanityKeyed<Publication> => {
  const { title, subtitle } = formatTitle(manifestation.title);

  return {
    id: manifestation.id,
    isbn: normalizeIsbn(manifestation.isbn[0]),
    title,
    subtitle,
    coverImage: downscaleBibbiImage(manifestation.coverImage ?? undefined),
    datePublished: manifestation.publicationYear,
    language: expression.languages[0]?.name,
    type: manifestation.documentType?.format,
    _type: "publication",
    _key: nanoid(),
  };
};

const getTags = (sortedManifestations: ManifestationWithExpression[]): ForrigebokWorkTags[] =>
  [sortedManifestations.some(({ manifestation }) => !!manifestation.kulturfond) && "kulturfond"].filter(
    Boolean
  ) as ForrigebokWorkTags[];

const sortManifestations = (manifestations: ManifestationWithExpression[]) =>
  [...(manifestations ?? [])]
    // Rekkefølgen bestemmer prioriteringen. Siste .sort() veier tyngst.
    .filter(({ manifestation }) => manifestation._origin?.name === "promus") // Stoler kun på poster vi har lagt inn selv, andre poster har ikke nødvendigvis stabile id'er
    .sort(comparePublicationsBy(({ manifestation }) => manifestation.id)) // Ser ut som publikasjoner ikke kommer i stabil rekkefølge så det varierer hvilken som er først og blir brukt til bla cover-bilde. Sorterer derfor først på id for å få likt utgangspunkt hver gang
    .sort(comparePublicationsBy(({ manifestation }) => manifestation.publicationYear ?? 0))
    .sort(comparePublicationsBy(({ expression }) => expression.expressionType.label === "Tekstlig uttrykk"))
    .sort(
      comparePublicationsBy(({ expression }) =>
        (["nno", "nob"] as const).some((lang) => expression.languages.map(({ code }) => code).includes(lang))
      )
    );

const comparePublicationsBy =
  (comparator: (manifestationWithExpression: ManifestationWithExpression) => string | boolean | number) =>
  (a: ManifestationWithExpression, b: ManifestationWithExpression) => {
    const aValue = comparator(a);
    const bValue = comparator(b);
    if (aValue === bValue) return 0;
    return aValue > bValue ? -1 : 1;
  };

const downscaleBibbiImage = (imageUrl?: string) => {
  // Nedskalerer bilder fra bibbi. Bibbi returnerer orginal-oppløsning som defualt. Det fører til mange store bilder (noen over 1mB)
  if (imageUrl?.startsWith("https://media.aja.bs.no")) return imageUrl.replace("/original", "/thumbnail");
  return imageUrl;
};
