import useNextTranslate from "next-translate/useTranslation";

export const supportedLanguages = ["nb", "nn"] as const;

export type SupportedLanguage = (typeof supportedLanguages)[number];

export type LocalizedProperty<T> = Partial<Record<SupportedLanguage, T>>;

export type SanityTranslator = <T>(localizedProperty?: LocalizedProperty<T>) => T | undefined;

export const useTranslation = () => {
  const { lang, ...nextTranslate } = useNextTranslate("common");

  const typedLang: SupportedLanguage = supportedLanguages.find((it) => it === lang) ?? "nb";

  const ts: SanityTranslator = (localizedProperty) => {
    const fallback = localizedProperty?.nb ?? localizedProperty?.nn ?? undefined;
    return (typedLang && localizedProperty?.[typedLang]) ?? fallback;
  };

  return { ts, lang: typedLang, ...nextTranslate };
};
