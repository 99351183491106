const { isDevelopment } = require("@biblioteksentralen/utils");

// Kopiert fra Library Content
module.exports = {
  extensionsRgx: /\.page\.tsx$/,
  pages: {
    // Since our volume of translations is not that high, and to simplify Poeditor setup, we use a single namespace
    "*": ["common"],
  },
  defaultNS: "common",
  // Use "default" to redirect requests with no locale: https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
  locales: ["nb", "nn"],
  defaultLocale: "nb",
  // Use the ICU interpolation format rather than the default "{{}}" for interop with eslint-plugin-i18n-json
  interpolation: { prefix: "{", suffix: "}" },
  // We disallow nested translations with `keySeparator: false`, since we use natural language keys – this also simplifies some ts types
  keySeparator: false,
  // Use namespace separator "::" rather than the default ":", since the latter will be in use in natural language keys
  nsSeparator: "::",
  // With `allowEmptyStrings: false`, missing translations will fall back to the key rather than displaying empty
  allowEmptyStrings: false,
  logger: ({ i18nKey }) => {
    const logger = isDevelopment() ? console.log : console.error;
    logger(`💬 Missing translation %c "${i18nKey}"`, "color: blue");
  },
};
