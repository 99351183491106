import { useCallback, useState } from "react";
import { retry } from "./retry";

export const fetchAndResolveErrors = async <R = undefined>(
  request: Request,
  options?: { retries: number }
): Promise<R> => {
  let response: Response | undefined;
  try {
    response = await retry(() => fetch(request), { retries: options?.retries ?? 0 });
  } catch (err: any) {
    throw new Error(`Fikk ikke kontakt med tjenesten pga. nettverksproblemer. Prøv igjen litt senere.`, {
      cause: new Error(`${err}, ${request.url}`),
    });
  }
  if (!response.ok) {
    let error;
    try {
      error = await response.json();
    } catch (err: any) {
      throw new Error(response?.statusText || err?.message || "Ugyldig respons fra tjenesten.");
    }
    throw new Error(error.message);
  }
  try {
    return await response.json();
  } catch (e) {
    return undefined as unknown as R;
  }
};

interface Success<R> {
  ok: true;
  data?: R;
}
interface Error {
  ok: false;
}

export function usePostRequest() {
  const [requestError, setRequestError] = useState<string | undefined>();
  const [state, setState] = useState<"idle" | "pending" | "error" | "success">("idle");

  const postJson = useCallback(async <R>(url: string, request: object): Promise<Success<R> | Error> => {
    setState("pending");
    setRequestError(undefined);
    try {
      const response = await fetchAndResolveErrors<R>(
        new Request(url, {
          method: "POST",
          body: JSON.stringify(request),
          headers: { "Content-Type": "application/json" },
        })
      );
      setState("success");
      return { ok: true, data: response };
    } catch (error: any) {
      setState("error");
      setRequestError(error.message);
      console.error(error);
      return { ok: false };
    }
  }, []);

  const reset = () => {
    if (state === "pending") return;
    setState("idle");
  };

  return {
    postJson,
    error: requestError,
    pending: state === "pending",
    success: state === "success",
    reset,
  };
}
