type Config = {
  retries: number;
};

export const retry = async <T>(fn: () => Promise<T>, options: Config): Promise<T> => run(fn, options, 0);

const run = async <T>(fn: () => Promise<T>, options: Config, retry: number): Promise<T> => {
  if (retry > 0) {
    console.log(`Promise failed. Retrying ${retry} / ${options.retries}`);
  }

  try {
    return await fn();
  } catch (e) {
    if (retry >= options.retries) {
      throw e;
    } else {
      return await run(fn, options, retry + 1);
    }
  }
};
