import { BibbiData, RelevantFor } from "@forrigebok/generatedtypes";
import { erIllustrertVerk } from "./utils";

export const bibbiAudienceAges = [
  "https://schema.nb.no/Bibliographic/Values/TG1000",
  "https://schema.nb.no/Bibliographic/Values/TG1001",
  "https://schema.nb.no/Bibliographic/Values/TG1002",
  "https://schema.nb.no/Bibliographic/Values/TG1003",
  "https://schema.nb.no/Bibliographic/Values/TG1004",
  "https://schema.nb.no/Bibliographic/Values/TG1005",
  "https://schema.nb.no/Bibliographic/Values/TG1015",
  "https://schema.nb.no/Bibliographic/Values/TG1016",
] as const;
export type BibbiAudienceAges = (typeof bibbiAudienceAges)[number];

export const forrigebokAudienceAges = [
  "0-2 år",
  "3-5 år",
  "6-8 år",
  "9-10 år",
  "11-12 år",
  "Ungdom",
  "Unge voksne",
  "Voksne",
] as const;
export type ForrigebokAudienceAges = (typeof forrigebokAudienceAges)[number];

export const bibbiAudienceToForrigebokAudienceMap: Record<BibbiAudienceAges, ForrigebokAudienceAges> = {
  "https://schema.nb.no/Bibliographic/Values/TG1000": "0-2 år",
  "https://schema.nb.no/Bibliographic/Values/TG1001": "3-5 år",
  "https://schema.nb.no/Bibliographic/Values/TG1002": "6-8 år",
  "https://schema.nb.no/Bibliographic/Values/TG1003": "9-10 år",
  "https://schema.nb.no/Bibliographic/Values/TG1004": "11-12 år",
  "https://schema.nb.no/Bibliographic/Values/TG1005": "Ungdom",
  "https://schema.nb.no/Bibliographic/Values/TG1015": "Unge voksne",
  "https://schema.nb.no/Bibliographic/Values/TG1016": "Voksne",
};

export type RelevantForConfig = Omit<RelevantFor, "_type">;

export const getVokabularFilter = (bibbiData: BibbiData): RelevantForConfig => ({
  barn: overlappendeAudienceAges(bibbiData, ["0-2 år", "3-5 år", "6-8 år", "9-10 år", "11-12 år", "Ungdom"]),
  voksen: overlappendeAudienceAges(bibbiData, ["Ungdom", "Unge voksne", "Voksne"]),
  illustrert: erIllustrertVerk(bibbiData),
});

const overlappendeAudienceAges = (bibbiData: BibbiData, audienceAges: ForrigebokAudienceAges[]) =>
  !!bibbiData.audienceAges?.some((audience) => audienceAges.includes(audience as ForrigebokAudienceAges));
