import useSWR from "swr";
import { jsonFetcher } from "@forrigebok/common";

interface CsrfTokenResponse {
  csrfToken?: string;
}

export function useCsrfToken(): string | undefined {
  const response = useSWR<CsrfTokenResponse>("/api/auth/csrf", jsonFetcher);
  return response.data?.csrfToken;
}
