import { Box, BoxProps, colors, HStack, Link, VisuallyHidden } from "@biblioteksentralen/react";
import Trans from "next-translate/Trans";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";
import Feilmelding from "../../Feilmelding";
import { useSanityStats } from "../../forside/useSanityStats";

export const MineRegistreringerLenke = () => {
  const { t } = useTranslation();
  return (
    <Link as={NextLink} href="/mine-registreringer" variant="plain" position="relative">
      <p>{t("Mine registreringer")}</p>
      <RegistreringerBadge />
    </Link>
  );
};

function RegistreringerBadge() {
  const { error, data } = useSanityStats();

  if (error) return <Feilmelding feilmelding={error} />;
  if (!data) return null;

  const { upubliserteRegistreringerCount } = data.brukersRegistreringer;

  return (
    <HStack spacing=".1em" fontSize="xs" lineHeight={1} fontWeight={600} position="absolute" right="-.5em" top="-.6em">
      {upubliserteRegistreringerCount && (
        <Badge backgroundColor={colors.statusYellow}>
          <Trans
            i18nKey="<Hidden>Du har </Hidden>{count}<Hidden> uferdige registreringer</Hidden>"
            // @ts-ignore
            components={{ Hidden: <VisuallyHidden /> }}
            values={{ count: upubliserteRegistreringerCount }}
          />
        </Badge>
      )}
    </HStack>
  );
}

const Badge = ({ children, ...chackraProps }: BoxProps) => (
  <Box as="p" color="white" display="inline-block" padding=".1em .3em" borderRadius="lg" {...chackraProps}>
    {children}
  </Box>
);
