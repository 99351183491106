import { useSession } from "next-auth/react";
import useSWR from "swr";
import { BrukerOrganisasjonResponse } from "../pages/api/internal/bruker/organisasjon.api";
import { jsonFetcher } from "@forrigebok/common";

export function useUser() {
  const sessionResponse = useSession();
  const sessionLoading = sessionResponse.status === "loading";
  const user = sessionResponse.data?.user?.email ? sessionResponse.data.user : undefined;
  const organisasjonResponse = useSWR<BrukerOrganisasjonResponse>(
    user ? "/api/internal/bruker/organisasjon" : null,
    jsonFetcher,
    {
      shouldRetryOnError: false,
    }
  );
  const organisasjonLoading = !organisasjonResponse.error && !organisasjonResponse.data;
  const loading = sessionLoading || (user && organisasjonLoading);

  const organisasjon = organisasjonResponse.data;

  return {
    loading,
    user,
    organisasjon,
    error: organisasjonResponse.error,
    onBoardingFinished: organisasjon?.name && user?.email,
  };
}
