import { Grid, Spinner, SpinnerProps } from "@chakra-ui/react";
import { SWRResponse } from "swr";
import Feilmelding from "./Feilmelding";

type Props<D> = {
  swrResponse: SWRResponse<D>;
  spinnerSize?: SpinnerProps["size"];
  centerSpinner?: boolean;
} & SpinnerProps;

// Komponent som håndterer gjentakende kode for visning av feilmelding og spinner mens vi venter på at swr fetcher data
export function SWRStatus<D>({ swrResponse, spinnerSize, centerSpinner, ...chakraProps }: Props<D>) {
  if (swrResponse.error) {
    return <Feilmelding {...chakraProps} feilmelding={swrResponse.error} />;
  }

  if (!swrResponse.data) {
    return centerSpinner ? (
      <CenteredSpinner {...chakraProps} size={spinnerSize} />
    ) : (
      <Spinner {...chakraProps} size={spinnerSize || "lg"} />
    );
  }

  return null;
}

export const CenteredSpinner = (props: SpinnerProps) => (
  <Grid placeItems="center" minHeight="15rem">
    <Spinner {...props} size={props.size ?? "xl"} />
  </Grid>
);
