import { Alert, Box, Container, Flex, Heading, Link, Stack, Text } from "@biblioteksentralen/react";
import { jsonFetcher } from "@forrigebok/common";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { AppVersionResponse } from "../pages/api/internal/version.api";
import { animations } from "../utils/animations";
import { vercelVersion } from "../utils/logVercelInfo";

const clientVersion = vercelVersion;

function NewVersionAlert() {
  const { data } = useSWR<AppVersionResponse>("/api/internal/version", jsonFetcher, { refreshInterval: 1000 * 120 });
  const { t } = useTranslation();

  const serverVersion = data?.version;

  if (!serverVersion || serverVersion === clientVersion) return null;

  return (
    <Box position="fixed" bottom=".5rem" width="100vw" animation={`${animations.slideUp} .4s`}>
      <Container maxW="container.sm">
        <Alert status="info">
          <Stack spacing=".2em" alignItems="flex-start">
            <Heading as="h2" size="sm">
              {t("Ny versjon av Forrigebok er tilgjengelig")}
            </Heading>
            <Text>{t("Noen funksjoner i Forrigebok vil kanskje ikke fungere før du laster inn siden på nytt.")}</Text>
            <Flex flexWrap="wrap" gap=".25rem .5rem">
              <Link as="button" display="flex" gap=".4em" alignItems="center" onClick={() => window.location.reload()}>
                {t("Last inn siden på nytt")}
              </Link>
              <Text>{t("(Arbeidet ditt er lagret)")}</Text>
            </Flex>
          </Stack>
        </Alert>
      </Container>
    </Box>
  );
}

export default NewVersionAlert;
