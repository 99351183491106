import { Registrering, SanityKeyed, TermMedScore, VerkSammendrag } from "@forrigebok/generatedtypes";
import { uniqueStrings } from "./uniqueStrings";
import { RegistreringerPåVerk } from "./updateSammendrag";
import { nanoid } from "nanoid";
import { sortArrayInGroups } from "@biblioteksentralen/utils";

/**
 Lager et sammendrag av alle registreringene på et verk
 
 Denne koden er litt kompleks, men legger grunnlaget for matching mellom bøker. Koden er visualisert på en side her: /dev/gloabl-weight
 
 Det som skjer her:
 Lagrer et sammendrag av alle registreringene på et verk. Legger dette i en liste med to forskjellige verdier: "averageWeight" og "globalWeight":
 
 - averageWeight: et gjennomsnitt av alle registreringene, frontendvennlig og lett å forstå. Hvis en bruker har merket en term som "fremtredende" vektes denne til 1, mens "normal" vektes til 0.5. Termer som ikke er valgt i det hele tatt vektes til 0. averageWeight beregnes enkelt ved å summere opp tallene og dele på antall registreringer. averageWeight er enkelt å forholde seg til, men utrykker ikke alle nyansene som er interessante ved matching, introduserer derfor globalWeight
 
 - globalWeight: basert på averageWeight, men justert for noen faktorer:
 
   1. Registreringer med få termer. I frontend anbefaler vi et sted mellom 5 og 20 termer. Det er rimelig å anta at en registrering med få termer bør vektes høyere fordi hver term sansynligvis er mer gjennomtenkt/viktigere for verket. Booster derfor bidragene fra registreringer med få termer. Hvis vi ikke gjør dette er det stor sansynlighet for at verk som har noen registreringer med få termer sjeldent vil bli trukket frem som readalike fordi kombinasjonen av snittet og overlapp av termene blir lavt i konkuranse med verk som har registreringer med mange termer.
 
   2. Verk med mange registreringer. Hvis et verk har mange nok registreringer er det nesten garantert ingen termer som har full averageWeight, altså 1. Likevel vil et verk der mange har gitt en term "fremtredende" og noen gitt den "normal" sansynligvis være en bedre match for termen enn et verk med en enste registrering der den ble merket som "fremtredende". Booster derfor verk som har mange registreringer.

 NB: Ikke alle termer fra alle registreringer blir med, bare de som til sammen havner over en hvis verdi. Tar alltid med de 15 termene ned høyest globalWeight, og alle termer som har minst like høy globalWeight som term nummer 15. Kan ikke kutte hardt på term 15, for det kan hende at feks term 16 har samme globalWeight.
 */

export function lagSammendrag(registreringer: RegistreringerPåVerk): VerkSammendrag {
  const antallRegistreringer = registreringer.length;

  if (!antallRegistreringer) {
    return {
      _type: "verkSammendrag",
      termer: [],
      antallRegistreringer: 0,
      organisasjonerSomHarRegistrert: [],
      updatedAt: new Date().toISOString(),
    };
  }

  const sortedAkumulerteTermvekter = getAkumulerteTermWeights(registreringer).sort((a, b) => b.global - a.global); // Sorterer så de med høyest globalWeight kommer først

  const cutofWeight = sortedAkumulerteTermvekter[15]?.global ?? 0; // Filtrerer bort termer som ikke går igjen på tvers av registreringer. Kan ikke kutte hardt på term 15, for det kan hende at feks term 16 har samme score

  const termerMedScore = sortedAkumulerteTermvekter
    .filter((term) => term.global >= cutofWeight)
    .slice(0, 40) // Øvre tak på antall termer
    .sort((a, b) => b.average - a.average) // Sorterer til slutt på average siden det er det som brukes i frontend og API
    .map(
      (akumulertTerm): SanityKeyed<TermMedScore> => ({
        _type: "termMedScore",
        _key: nanoid(),
        term: { _type: "reference", _ref: akumulertTerm.id },
        averageWeight: numberWithTwoDecimals(akumulertTerm.average),
        globalWeight: numberWithTwoDecimals(akumulertTerm.global),
      })
    );

  const organisasjoner = uniqueStrings(registreringer?.map((it) => it.organisasjon) ?? []);

  return {
    _type: "verkSammendrag",
    termer: termerMedScore,
    organisasjonerSomHarRegistrert: organisasjoner,
    antallRegistreringer: antallRegistreringer,
    updatedAt: new Date().toISOString(),
  };
}

type AkumulertTermWeight = { id: string; average: number; global: number };

// Slår sammen flere registreringer i en felles liste med termer
const getAkumulerteTermWeights = (registreringer: Registrering[]): AkumulertTermWeight[] => {
  const antallRegistreringer = registreringer.length;

  const alleTermRegistreringerMedBeregnetWeightContribution = registreringer.flatMap((registrering) => {
    const termerIRegistrering = registrering.registrerteTermer ?? [];
    const antallTermerIRegistrering = termerIRegistrering.length;
    return termerIRegistrering.flatMap((termRegistrering) => {
      const averageContribution = (termRegistrering.vekt === "fremtredende" ? 1 : 0.5) / antallRegistreringer;

      const globalContribution =
        averageContribution *
        (1.75 - 1 / antallRegistreringer) * // Belønner verk som har mange registreringer ved å gange med (1.75 - 1 / antallRegistreringer) som er 0.75 for 1 registering, og går mot 1.75 for uendelig antall registreringer.
        (1 / (antallTermerIRegistrering / 30 + 1)); // Belønner registreringer med få termer. Om man bare har registrert 5 termer feks antar vi at disse er veldig "viktige" og derfor kan vektes høyere. kjør "new Array(30).fill(0).map((_,i) => (1 / (i / 30 + 1)).toFixed(2))" i feks terminal for å se hvilke vekter som kommer for et gitt antall registreringer

      return {
        id: termRegistrering.term?._ref ?? "N/A",
        averageContribution,
        globalContribution,
      };
    });
  });

  return sortArrayInGroups(alleTermRegistreringerMedBeregnetWeightContribution, (it) => it.id).map((it) => ({
    id: it.label,
    average: sum(it.items, (item) => item.averageContribution),
    global: sum(it.items, (item) => item.globalContribution),
  }));
};

const numberWithTwoDecimals = (number: number) => Number(number.toFixed(2));

const sum = <T>(items: T[], sumBy: (item: T) => number) => items.reduce((acc, current) => acc + sumBy(current), 0);
