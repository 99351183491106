import {
  Box,
  Button,
  ChakraProps,
  colors,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@biblioteksentralen/react";
import { jsonFetcher } from "@forrigebok/common";
import { FunctionComponent } from "react";
import { useTranslation } from "src/utils/useTranslation";
import useSWR from "swr";
import { UinnloggetForsideResponse } from "../../pages/api/internal/forside/uinnlogget.api";
import { useCsrfToken } from "../../utils/useCsrfToken";
import Feilmelding from "../Feilmelding";
import { ChangeLocale } from "../layout/header/ChangeLocale";
import VimeoVideo from "../VimeoVideo";
import { TaKontaktMedBibsent } from "./Onboarding";

export const Innlogging = () => {
  const csrfToken = useCsrfToken();
  const { t } = useTranslation();

  return (
    <Container maxW="110rem" padding={0} minH="100%" display="flex" alignItems="stretch">
      <Flex flexDirection={{ base: "column", lg: "row" }} width="100%">
        <Flex
          flex="0 1 30rem"
          direction="column"
          justifyContent="space-between"
          backgroundColor={colors.grey05}
          color="black"
        >
          <ChangeLocale alignSelf="flex-end" padding="1rem" linkProps={{ variant: undefined, fontWeight: 600 }} />
          <Stack padding={{ base: "2rem", md: "4rem" }} maxW="30rem" alignSelf="center">
            <Text fontWeight={600}>{t("Velkommen til")}</Text>
            <Heading as="h1" size="2xl" color={colors.neptune[800]}>
              Forrigebok
            </Heading>
            <Text>
              {t(
                "Et digitalt verktøy for å jobbe med lesersørvis og registrering av appellfaktorer knyttet til bøker i norske folke- og skolebibliotek."
              )}
            </Text>
            <VStack data-nosnippet marginTop="5vmin !important" spacing="1rem">
              {/** data-nosnippet sørger for at innloggingsform ikke havner som tekst-utdrag i google-søk. https://developers.google.com/search/docs/advanced/robots/robots_meta_tag#data-nosnippet-attr */}
              {!csrfToken ? (
                <Spinner />
              ) : (
                <>
                  <LoginForm csrfToken={csrfToken} type="logIn" />
                  <Text fontSize="sm">eller</Text>
                  <HStack fontWeight={600}>
                    <Text>{t("Er du ny?")}</Text>
                    <LoginForm csrfToken={csrfToken} type="signUp" />
                  </HStack>
                </>
              )}
            </VStack>
          </Stack>
          <Text textAlign={{ base: "center", md: "end" }} padding="4rem 2rem 2rem" fontWeight={600}>
            {t("Problemer med innlogging?")} <TaKontaktMedBibsent />
          </Text>
        </Flex>
        <Flex flexGrow="1" alignItems="center" backgroundColor={colors.neptune[500]}>
          <Video flex="1" />
        </Flex>
      </Flex>
    </Container>
  );
};

function Video(props: ChakraProps) {
  const { error, data } = useSWR<UinnloggetForsideResponse>("/api/internal/forside/uinnlogget", jsonFetcher);

  if (error) return <Feilmelding feilmelding={error} />;
  if (!data?.velkomstvideo?.vimeoId) return null;

  return <VimeoVideo borderRadius={0} video={data.velkomstvideo} {...props} />;
}

interface LoginFormProps {
  type: "signUp" | "logIn";
  csrfToken?: string;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ csrfToken, type: variant }) => {
  const { t } = useTranslation();

  if (!csrfToken) {
    return null;
  }

  const url =
    variant === "logIn"
      ? `/api/auth/signin/auth0`
      : `/api/auth/signin/auth0?${new URLSearchParams({ screen_hint: "signup" }).toString()}`;

  return (
    <Box as="form" method="POST" action={url} alignSelf="stretch">
      <input type="hidden" name="csrfToken" value={csrfToken} />
      <input type="hidden" name="callbackUrl" value={window.location.href} />
      {variant === "logIn" ? (
        <Button
          backgroundColor={colors.neptune[600]}
          _hover={{ backgroundColor: colors.neptune[700] }}
          color="white"
          type="submit"
          minW="100%"
        >
          {t("Logg på med jobb-epost")}
        </Button>
      ) : (
        <Link as="button" type="submit" fontWeight={600}>
          {t("Opprett bruker her")}
        </Link>
      )}
    </Box>
  );
};
