import { Alert, Button, colors, Text } from "@biblioteksentralen/react";
import { Link } from "@chakra-ui/react";
import { FunctionComponent, useCallback, useState } from "react";
import { usePostRequest } from "@forrigebok/common";
import { loggUt } from "../layout/header/LogUtKnapp";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

interface Props {
  token: string;
}

const SendBekreftelsesEpost: FunctionComponent<Props> = ({ token }) => {
  const [isResent, setIsResent] = useState<boolean>(false);
  const request = usePostRequest();
  const { t } = useTranslation();

  const resendVerificationEmail = useCallback(() => {
    if (request.pending) return; // For å hindre dobbelregistrering
    setIsResent(false);
    return request
      .postJson("/api/auth/send-verification-email", {
        token,
      })
      .then((response) => response.ok && setIsResent(true));
  }, [request, token]);

  if (isResent)
    return (
      <>
        <Alert variant="inline" status="success" marginY="3rem !important">
          {t("Vi har sendt deg en ny e-post.")}
        </Alert>
        <Text>
          <Trans
            i18nKey="Hvis du fortsatt ikke får epost eller har oppgitt feil epost kan du <Link>starte på nytt</Link>"
            components={{ Link: <Link as="button" onClick={loggUt} /> }}
          />
        </Text>
      </>
    );

  if (request.error)
    return (
      <Alert variant="inline" status="error">
        {t("Beklager, noe gikk galt. Prøv å logge inn på nytt.")}
      </Alert>
    );

  return (
    <>
      <Text marginTop="2rem !important">{t("Vi har sendt deg en e-post.")}</Text>
      <Button
        backgroundColor={colors.grey10}
        _hover={{ backgroundColor: "white" }}
        color="black"
        type="submit"
        isLoading={request.pending}
        onClick={resendVerificationEmail}
      >
        {t("Send på nytt")}
      </Button>
      <Text marginTop="2rem !important">
        <Trans
          i18nKey="Har du oppgitt feil adresse? Da kan du <Link>starte registreringen på nytt</Link>."
          components={{ Link: <Link as="button" onClick={loggUt} /> }}
        />
      </Text>
    </>
  );
};

export default SendBekreftelsesEpost;
