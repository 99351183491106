import { BibbiData } from "@forrigebok/generatedtypes";

export function getVerkUrl(bibbiData?: Pick<BibbiData, "workId" | "publications">) {
  if (!bibbiData) return "/verk";

  if (!bibbiData?.workId) {
    return `/verk/?isbn=${bibbiData.publications?.[0]?.isbn}`;
  }

  return `/verk/?workId=${bibbiData.workId}`;
}
