import { Box, BoxProps } from "@biblioteksentralen/react";

export const HideOnPrint = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      "@media print": {
        display: "none",
      },
    }}
  />
);

export const OnlyShowOnPrint = (props: BoxProps) => (
  <Box
    {...props}
    display="none"
    sx={{
      "@media print": {
        display: "block",
      },
    }}
  />
);
