import { colors, Link } from "@biblioteksentralen/react";
import { Box, BoxProps, Heading, HeadingProps } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { RichText as RichTextI } from "@forrigebok/generatedtypes";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import SanityImage from "./RichTextBilde";
import VimeoVideo from "./VimeoVideo";

const richTextStyle = css`
  width: 100%;
  container-type: inline-size;
  > {
    * {
      max-width: "30em";
      margin-left: auto;
      margin-right: auto;
    }
    p:not(:last-child) {
      line-height: 1.6;
      margin-bottom: 1.75em;
      @container (max-width: 20em) {
        margin-bottom: 0.75em;
      }
    }
    ul,
    ol {
      padding-left: 1.5em;
      margin-bottom: 2.5em;
      @container (max-width: 20em) {
        margin-bottom: 1em;
      }
      ul,
      ol {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 0.3em;
        padding-left: 0.3em;
        line-height: 1.7;
        @container (max-width: 20em) {
          margin-bottom: 0;
        }
      }
    }
    ul {
      list-style: disc;
      ul {
        list-style: circle;
      }
    }
    ol {
      list-style: decimal;
    }
  }
`;

const components = (headingProps?: HeadingProps): Partial<PortableTextReactComponents> => ({
  block: {
    h2: ({ children }) => (
      <Heading as="h2" fontSize="1.5rem" marginTop="calc(2vmin + 2rem)" {...headingProps}>
        {children}
      </Heading>
    ),
  },
  types: {
    video: ({ value }) => <VimeoVideo marginBottom="calc(3vmin + 3rem)" video={value} />,
    bilde: ({ value }) => (
      <SanityImage width={800} margin="3rem auto" marginBottom="calc(3vmin + 3rem)" bilde={value} />
    ),
  },
  marks: {
    link: ({ children, value }) => (
      <Link
        textDecoration="none"
        borderBottom={`1px solid ${colors.accentBlueMedium}`}
        _hover={{ color: colors.accentBlueMedium }}
        href={value.href}
      >
        {children}
      </Link>
    ),
  },
});

interface Props extends BoxProps {
  blocks?: RichTextI;
  headingsProps?: HeadingProps;
}

function RichText({ blocks, headingsProps, children, ...chakraProps }: Props) {
  return (
    <Box css={richTextStyle} {...chakraProps}>
      {children}
      <PortableText value={blocks || []} components={components(headingsProps)} />
    </Box>
  );
}

export default RichText;
