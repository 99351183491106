import { bmdbFetcher } from "@biblioteksentralen/bmdb-search-client";
import { BibbiData } from "@forrigebok/generatedtypes";
import { createBibbiDataFromCordataWork } from "./createBibbiDataFromCordataWork";

type Data = {
  moreResultsExist: boolean;
  works: BibbiData[];
};

const numberOfResults = 20;

export const bibbiSearch = async (query: string) => {
  const bmdbResponse = await bmdbFetcher({
    catalogueId: "global",
    operation: "searchWorks",
    clientIdentifier: "Forrigebok",
    params: {
      query,
      filter: { "plas.mediaTypes": ["book"], "bmdb.workOrigin": ["promus"] },
      size: numberOfResults + 1, // Fetching one more than we need to check if there are more results
    },
  });

  const results = bmdbResponse?.results ?? [];

  const convertedWorks = results
    .slice(0, numberOfResults) // Removing the extra result used to check if there are more results
    .map((result) => createBibbiDataFromCordataWork(result.work));

  return {
    works: convertedWorks,
    moreResultsExist: results.length > convertedWorks.length,
  } satisfies Data;
};
