import { Grid, Heading, Link, LinkProps, StackProps, Text, VStack, withErrorBoundary } from "@biblioteksentralen/react";
import Trans from "next-translate/Trans";
import { useRouter } from "next/router";
import { FunctionComponent } from "react";
import { useTranslation } from "src/utils/useTranslation";
import { useUser } from "../../utils/useUser";
import { loggUt } from "../layout/header/LogUtKnapp";
import EpostBekreftet from "./EpostBekreftet";
import { Innlogging } from "./Innlogging";
import SendBekreftelsesEpost from "./SendBekreftelsesEpost";

const Onboarding: FunctionComponent = () => {
  const router = useRouter();
  const { user, organisasjon, error } = useUser();
  const { t } = useTranslation();

  const auth0status = router.query["auth0status"];
  const auth0email = router.query["auth0email"];
  const auth0token = router.query["auth0token"];

  // Omdirigering fra Auth0 etter innlogging hvis e-post ikke er bekreftet
  if (auth0status === "email_verification_required" && typeof auth0token === "string")
    return (
      <OnboardingWrapper>
        <Heading as="h1">{t("Velkommen")}</Heading>
        <Text fontWeight={600} fontSize="lg">
          {t("Du må bekrefte e-postadressen din ({email}) før du kan fortsette.", { email: auth0email })}
        </Text>
        <SendBekreftelsesEpost token={auth0token} />
      </OnboardingWrapper>
    );

  // Omdirigering fra Auth0 etter bekreftelse av e-post
  if (auth0status === "email_verified") return <EpostBekreftet />;

  if (!user) return <Innlogging />;

  if (!organisasjon)
    return (
      <OnboardingWrapper>
        <Heading>{t("Ukjent organisasjon")}</Heading>
        <Text fontWeight={600} textAlign="center" fontSize="lg">
          {t("E-post-adressen din ({email}) tilhører ikke en organisasjon vi kjenner igjen.", { email: user.email })}
        </Text>
        <Text>
          <TaKontaktMedBibsent /> {t("for å få lagt den til.")}
        </Text>
        <Text marginTop="3rem !important">
          <Trans i18nKey="Feil epost? <Link>Start på nytt her.</Link>." components={{ Link: <LoggUtKnapp /> }} />
        </Text>
      </OnboardingWrapper>
    );

  console.error(error ?? new Error("Noe gikk galt med innlogging"));

  return (
    <OnboardingWrapper>
      <Heading>{t("Noe gikk galt")}</Heading>
      <Text fontWeight={600} textAlign="center" fontSize="lg">
        {typeof error?.message === "string" ? error.message : t("Beklager, noe gikk galt med innlogging")}
      </Text>
      <Text>
        <TaKontaktMedBibsent /> {t("hvis problemet vedvarer.")}
      </Text>
    </OnboardingWrapper>
  );
};

const OnboardingWrapper: FunctionComponent<StackProps> = (props) => (
  <Grid height="90%" placeItems="center" padding="7rem 1rem" textAlign="center">
    <VStack {...props} />
  </Grid>
);

const LoggUtKnapp: FunctionComponent<LinkProps> = (props) => <Link as="button" onClick={loggUt} {...props} />;

export const TaKontaktMedBibsent = () => {
  const { t } = useTranslation();
  return <Link href="https://www.bibsent.no/kundesenter/">{t("Ta kontakt med Biblioteksentralen")}</Link>;
};

export default withErrorBoundary(Onboarding, "Onboarding");
