/* eslint-disable i18next/no-literal-string */
import { colors, Flex } from "@biblioteksentralen/react";
import styled from "@emotion/styled";
import { getSanityDataset } from "@forrigebok/common";
import { AlertCircle } from "react-feather";

const Style = styled(Flex)`
  color: white;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  font-size: 0.7rem;
`;

const testNamespace = process.env.E2E_TEST_SANITY_NAMESPACE;

function Testenvironmentbanner() {
  if (getSanityDataset() === "production") {
    return null;
  }

  if (testNamespace) {
    return (
      <Style backgroundColor={colors.bsRødMedium}>
        <AlertCircle />
        <span>
          Dette er et testmiljø satt opp for automatiske tester. Data forsvinner plutselig. dataset:
          {getSanityDataset()}/namespace:{testNamespace}
        </span>
      </Style>
    );
  }

  return (
    <Style backgroundColor={colors.statusYellow}>
      <AlertCircle size="1em" />
      <span>Dette er et testmiljø, data forsvinner plutselig (dataset: {getSanityDataset()})</span>
    </Style>
  );
}

export default Testenvironmentbanner;
