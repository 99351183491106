import { keyframes } from "@emotion/react";

const popIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-.5rem) scale(0.7);
  }
`;

const slideDown = keyframes`
from {
    opacity: 0;
    transform: translateY(-2rem) scale(0.9);
}
`;

const slideInLeft = keyframes`
from {
    opacity: 0;
    transform: translateX(-4rem) scale(0.9);
}
`;

const slideUp = keyframes`
from {
    opacity: 0;
    transform: translateY(2rem) scale(0.9);
}`;

const fadeIn = keyframes`
from {
    opacity: 0;
}`;

export const animations = {
  popIn,
  fadeIn,
  slideDown,
  slideUp,
  slideInLeft,
};
