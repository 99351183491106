import { Box, colors, withErrorBoundary } from "@biblioteksentralen/react";
import { useTranslation } from "src/utils/useTranslation";

type Error = any | string | { message: string };

interface Props {
  feilmelding?: Error;
}

function Feilmelding({ feilmelding }: Props) {
  const errorString = getErrorString(feilmelding);
  const { t } = useTranslation();

  if (!feilmelding) return null;

  return (
    <Box fontStyle="italic" textAlign="center" color={colors.bsRødMedium} role="alert">
      <Box>{t("Beklager, det skjedde en uventet feil. Prøv igjen om litt.")}</Box>
      {errorString && <Box fontSize="xs">Utviklerinfo: «{errorString}»</Box>}
    </Box>
  );
}

const getErrorString = (error?: Error) => {
  if (!error) return null;

  if (typeof error?.message === "string") return error.message;
  if (typeof error === "string") return error;

  console.error("Kunne ikke vise feilmelding:", error);

  return null;
};

export default withErrorBoundary(Feilmelding, "Feilmelding");
