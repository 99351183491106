import { colors } from "@biblioteksentralen/react";
import { seededRandom } from "@biblioteksentralen/utils";

export const accentColors = [
  colors.accentPeach,
  colors.lightGreen,
  colors.lightPeach,
  colors.accentBlueMedium,
  colors.lightBlue,
];

export const getAccentColorFromSeed = (seed: string) => {
  const index = Math.floor(seededRandom(seed) * accentColors.length);
  return accentColors[index];
};

export const fremtredendeFarge = colors.neptune["700"];
export const normalFarge = colors.neptune["500"];
