import { Varsel as VarselI } from "@forrigebok/generatedtypes";
import { Alert, Box, ChakraProps, Container } from "@biblioteksentralen/react";
import { formaterDato } from "@biblioteksentralen/utils";
import useSWR from "swr";
import RichText from "./RichText";
import { jsonFetcher } from "@forrigebok/common";

function Varsel(chakraProps: ChakraProps) {
  const { data } = useSWR<VarselI>("/api/internal/varsel", jsonFetcher);

  if (!data?.show) return null;

  return (
    <Container maxW="container.lg" {...chakraProps}>
      <Alert status="warning" color="black">
        <Box>
          <RichText blocks={data.body}>
            <Box float={{ sm: "right" }} paddingLeft={{ sm: "1rem" }} fontStyle="italic" opacity={0.8} fontSize="sm">
              Oppdatert {formaterDato(data._updatedAt, "PPPp")}
            </Box>
          </RichText>
        </Box>
      </Alert>
    </Container>
  );
}

export default Varsel;
