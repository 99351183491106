import { ChakraProps, Container, Flex, Link, Text, withErrorBoundary } from "@biblioteksentralen/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import StarProgressBar from "../../../ikoner/StarProgressBar";
import { normalFarge } from "../../../utils/colors";
import { useUser } from "../../../utils/useUser";
import { HideOnPrint } from "../../HideOnPrint";
import { ChangeLocale } from "./ChangeLocale";
import { LogUtKnapp } from "./LogUtKnapp";
import { MineRegistreringerLenke } from "./MineRegistreringerLenke";
import SearchInput from "./SearchInput";
import { MinStatistikkLenke } from "./MinStatistikkLenke";

function Header() {
  const { onBoardingFinished } = useUser();
  const router = useRouter();
  const showSearchBar = onBoardingFinished && router.pathname !== "/";

  return (
    <HideOnPrint
      as="header"
      role="banner"
      backgroundColor={normalFarge}
      color={"white"}
      padding={{ base: "1rem 0", md: ".75rem 0" }}
    >
      <Container maxW="container.xl">
        <Flex
          minH={{ md: "2.5rem" }} // Ca høyden som blir med søkefelt som ikke er synlig/tar plass på forsiden
          gap=".6rem 1.5rem"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          as="nav"
        >
          <Flex flex={1} order={1}>
            <Logo />
          </Flex>
          {showSearchBar && <SearchInput flex={2} order={{ base: 3, md: 2 }} maxW={{ md: "27rem" }} />}
          <Flex
            whiteSpace="nowrap"
            fontWeight={600}
            justifyContent="flex-end"
            flex={1}
            gap={{ base: ".75rem", md: "1.5rem" }}
            fontSize={{ base: "sm", sm: "md" }}
            alignSelf={{ base: "flex-end", md: "center" }}
            order={{ base: 2, md: 3 }}
          >
            {onBoardingFinished && <MineRegistreringerLenke />}
            {onBoardingFinished && <MinStatistikkLenke />}
            <ChangeLocale justifyContent="flex-end" />
            {onBoardingFinished && <LogUtKnapp />}
          </Flex>
        </Flex>
      </Container>
    </HideOnPrint>
  );
}

export function Logo(chakraProps: ChakraProps) {
  return (
    <Link
      as={NextLink}
      href="/"
      variant="plain"
      display="flex"
      gridGap=".5em"
      alignItems="center"
      lineHeight={1}
      {...chakraProps}
    >
      <StarProgressBar width="1.6em" progress={1} />
      <Text fontSize="lg" fontWeight={600} marginTop=".125em">
        Forrigebok
      </Text>
    </Link>
  );
}

export default withErrorBoundary(Header, "Header");
