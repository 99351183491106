import { Registrering, Verk, VerkSammendrag } from "@forrigebok/generatedtypes";
import groq from "groq";
import { lagSammendrag } from "./lagSammendragAvRegistreringer";
import { SanityClient } from "@sanity/client";
import { isEqual, omit } from "radash";

const registreringerPåVerkQuery = groq`*[_type == "registrering" && publisert && verk->_id == $id][]{
  ...,
  "organisasjon": registrertAv.organisasjon->name,
  "registrertAvBS": "bibsent.no" in registrertAv.organisasjon->domains
}
`;

export type RegistreringerPåVerk = (Registrering & { organisasjon: string; registrertAvBS: boolean })[];

export async function updateSammendrag(verk: Verk, client: Pick<SanityClient, "fetch" | "patch">) {
  const registreringer: RegistreringerPåVerk = await client.fetch(registreringerPåVerkQuery, { id: verk._id });

  const nyttSammendrag = lagSammendrag(registreringer);

  if (!sammendragHasChanged(verk.sammendrag, nyttSammendrag)) return;

  const updatedData: Pick<Verk, "sammendrag"> = {
    sammendrag: nyttSammendrag,
  };

  const unsetReadalikesCacheOperation: (keyof Verk)[] = ["readalikesCache"]; // Nullstiller readalikes-cache når registrerte termer på verk oppdateres, vanligvis fordi noen har laget/oppdatert eller slettet en registrering på verket

  return client.patch(verk._id).set(updatedData).unset(unsetReadalikesCacheOperation).commit();
}

export const sammendragHasChanged = (currentSammendrag?: VerkSammendrag, nyttSammendrag?: VerkSammendrag) =>
  !isEqual(getComparableSammendrag(currentSammendrag), getComparableSammendrag(nyttSammendrag));

const getComparableSammendrag = (sammendrag?: VerkSammendrag) => sammendrag && omit(sammendrag, ["updatedAt"]);
