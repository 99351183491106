import { css, Global } from "@emotion/react";

export const GlobalStyling = () => (
  <Global
    styles={css`
      html {
        font-size: 105% !important;
      }
      // https://www.smashingmagazine.com/2011/11/how-to-set-up-a-print-style-sheet/
      @media print {
        html {
          font-size: 100% !important;
        }
        nav {
          display: none;
        }
        @page {
          margin: 0.5cm;
        }
      }
    `}
  />
);
