import { BibbiPublication } from "./BibbiPublication";
import { CatmanduWork, Creator } from "./CatmanduWork";

export type SimulatedCatmanduWork = Pick<CatmanduWork, "publications" | "name" | "creator"> & {
  simulated: true;
};

export const createSimulatedCatmanduWorkFromPublication = (publication: BibbiPublication): SimulatedCatmanduWork => {
  if (!publication.isbn) throw new Error(`Publikasjon mangler isbn, kan ikke lage simulert verk`);

  const simulatedWorkPublication: SimulatedCatmanduWork["publications"][number] = {
    name: publication.name,
    isbn: publication.isbn,
    id: publication.id,
    language: publication.inLanguage,
    image: publication.image?.thumbnailUrl ?? null,
    description: publication.description ?? undefined,
    genre: publication.genre,
    about: publication.about,
    audience: publication.audience,
    "@type": "Book",
  };

  return {
    name: publication.name,
    creator: getMainContributors(publication),
    simulated: true,
    publications: [simulatedWorkPublication],
  };
};

function getMainContributors({ creator }: BibbiPublication): Creator[] {
  const authors = creator?.filter((it) => it.role === "aut");
  if (authors.length) return authors;
  const red = creator?.filter((it) => it.role === "red");
  if (red.length) return red;
  if (creator[0]) return [creator[0]];
  return [];
}

export function isSimulatedCatmanduWork(work: SimulatedCatmanduWork | CatmanduWork): work is SimulatedCatmanduWork {
  return !!(work as SimulatedCatmanduWork).simulated;
}
