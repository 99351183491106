import { isDevelopment } from "@biblioteksentralen/utils";
import { FilteredResponseQueryOptions } from "@sanity/client";
import createImageUrlBuilder from "@sanity/image-url";
import { SanityImageObject, SanityProjectDetails } from "@sanity/image-url/lib/types/types";
import { nanoid } from "nanoid";
import { ClientConfig, createClient } from "next-sanity";

const token = process.env.SANITY_TOKEN;
const dataset = process.env.SANITY_STUDIO_API_DATASET;
const E2E_TEST_SANITY_NAMESPACE = process.env.E2E_TEST_SANITY_NAMESPACE;

if (isDevelopment()) {
  console.log("💾 Current dataset: " + dataset);
}
if (E2E_TEST_SANITY_NAMESPACE) {
  console.log(`🧪 Using dataset "${dataset}" and test namespace "${E2E_TEST_SANITY_NAMESPACE}"`);
}

const config: ClientConfig = {
  dataset: dataset || "placeholder",
  projectId: "5vl8a8pf",
  apiVersion: "v2022-07-20",
  token: token,
  useCdn: false,
};

export const cdnSanityClient = createClient({ ...config, useCdn: true });

export const imageUrlBuilder = (source?: SanityImageObject) =>
  source ? createImageUrlBuilder(config as SanityProjectDetails).image(source) : undefined;

/**
 * We have to modify the default client to make e2e-tests oparate within a namespace for concurrent e2e-test runs
 *  */
// The client is not exported, to ensure insertion of potential document paths
const rawSanityClient = createClient(config);

export const sanityClient = {
  // @ts-ignore No, can not be written like this "patch: rawSanityClient.patch" or "...rawSanityClient" , if you do it doesnt find it self because it's a class instance or something 🤷‍♀️
  patch: rawSanityClient.patch.bind(rawSanityClient),
  mutate: rawSanityClient.mutate.bind(rawSanityClient),
  transaction: rawSanityClient.transaction.bind(rawSanityClient),
  delete: rawSanityClient.delete.bind(rawSanityClient),
  config: rawSanityClient.config.bind(rawSanityClient),
  fetch: modifiedFetch,
  create: modifiedCreate,
  createIfNotExists: rawSanityClient.createIfNotExists.bind(rawSanityClient),
  withConfig: rawSanityClient.withConfig.bind(rawSanityClient),
};

// To provide isolation of e2e test data we namespace the document IDs (https://www.sanity.io/docs/ids#fdc25ada5db2)
export const isE2ETest = Boolean(E2E_TEST_SANITY_NAMESPACE);

async function modifiedCreate({ _type, ...rest }) {
  return await rawSanityClient.create({
    _type,
    ...rest,
    ...(isE2ETest ? { _id: `${E2E_TEST_SANITY_NAMESPACE}.${nanoid()}` } : {}),
  });
}

async function modifiedFetch(
  query: string,
  params?: { [key: string]: any },
  options: FilteredResponseQueryOptions & { ignoreTestNamespace?: boolean } = {}
) {
  const { ignoreTestNamespace, ...fetchOptions } = options;
  if (!isE2ETest || ignoreTestNamespace) {
    return await rawSanityClient.fetch(query, params, fetchOptions);
  }

  const documentNamespaceFilter = `*[ _id in path("${E2E_TEST_SANITY_NAMESPACE}.*") && `;
  const queryWithDocumentNamespaceFilter = query.replace(/\* ?\[ ?/g, documentNamespaceFilter);

  return await rawSanityClient.fetch(queryWithDocumentNamespaceFilter, params, fetchOptions);
}
