import { Link } from "@biblioteksentralen/react";
import { signOut } from "next-auth/react";
import { useTranslation } from "src/utils/useTranslation";

export const loggUt = () => signOut({ callbackUrl: "/api/auth/auth0signout" });

export const LogUtKnapp = () => {
  const { t } = useTranslation();
  return (
    <Link variant="plain" fontWeight={600} as="button" onClick={() => loggUt()}>
      {t("Logg ut")}
    </Link>
  );
};
