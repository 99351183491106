import { BibbiData, Publication, SanityKeyed } from "@forrigebok/generatedtypes";
import { nanoid } from "nanoid";
import { bibbiAudienceToForrigebokAudienceMap, ForrigebokAudienceAges } from "./audienceAges";
import { CatmanduWork } from "./CatmanduWork";
import { isSimulatedCatmanduWork, SimulatedCatmanduWork } from "./SimulatedCatmanduWork";
import { ForrigebokWorkTags } from "./tags";

export function createBibbiDataFromCatmandu(work: CatmanduWork | SimulatedCatmanduWork): BibbiData {
  const workId = isSimulatedCatmanduWork(work) ? undefined : work.id;
  const sortedPublications = sortPublications(work.publications);
  const sortedPartOfPublications = isSimulatedCatmanduWork(work) ? [] : sortPublications(work.partOfPublications);
  const sortedPublicationsOrPartOfPublications =
    sortedPublications.length > 0 ? sortedPublications : sortedPartOfPublications;
  const publications = sortedPublications.map(getPublication);
  const partOfPublications = sortedPartOfPublications.map(getPublication);
  const { title, subtitle } = getName(work, sortedPublications);
  const authors = work.creator?.map((creator) => creator.name) ?? [];
  const searchableText = [title, subtitle, ...authors, workId, ...publications.map((pub) => pub.isbn)]
    .filter(Boolean)
    .join(" ");

  const bibbiData: BibbiData = {
    _type: "bibbiData",
    workId,
    title,
    subtitle,
    authors,
    // Bruker coverbilde fra part of publications dersom det finnes
    coverImage: getCoverImage(sortedPublicationsOrPartOfPublications),
    description: getDescription(sortedPublicationsOrPartOfPublications),
    about: getAbout(sortedPublicationsOrPartOfPublications),
    genre: getGenre(sortedPublicationsOrPartOfPublications),
    originalYear: isSimulatedCatmanduWork(work) ? undefined : work.originalYear,
    publications,
    partOfPublications,
    workTypes: isSimulatedCatmanduWork(work) ? [] : work.workTypes.map((type) => type.label),
    audienceAges: getAudienceAges(sortedPublicationsOrPartOfPublications),
    updatedAt: new Date().toISOString(),
    searchableText,
    tags: getTags(work),
  };

  if (!bibbiData.title) {
    throw new Error(
      `Det skjedde en feil ved parsing av katalogdata (verksId: ${workId} / isbn: ${bibbiData.publications?.[0]?.isbn})`
    );
  }

  return bibbiData;
}

/**
 * TODO, her utledes en del info om verket fra de enkelte publikasjonene i verket.
 * På sikt bør vel egentlig denne utledingen ligge i bibbi og leveres på "verksnivå" så klienter slipper å utlede ting som sjanger/emne/sammendrag selv og ende opp med forskjellige implementasjoner
 */

const getName = (
  work: CatmanduWork | SimulatedCatmanduWork,
  sortedPublications: CatmanduWork["publications"]
): Pick<BibbiData, "title" | "subtitle"> => {
  const name =
    // Prøver føsrt å finne en norsk publikasjon og tar tittel derfra
    sortedPublications.find((publication) => ["nno", "nob"].includes(publication.language))?.name ??
    // Fallbacker til verkstittel hvis det ikke finnes noen norsk publikasjon
    work.name ??
    // Fallbacker til en tilfeldig publikasjon hvis vi fortsatt ikke har funnet et navn vi kan bruke
    sortedPublications[0]?.name;

  if (!name)
    throw new Error(
      `Fant ikke tittel for verk (${isSimulatedCatmanduWork(work) ? work.publications[0]?.isbn : work.id})`
    );

  return splitName(name);
};

const splitName = (name: string) => {
  const [title, resten] = name?.split(/ : (.+)/) ?? []; // Splitter på " : " (med space forran og bak) for å håndtere caser som denne: https://www.bibsent.no/boker-medier/boker/skjonnlitteratur/15-25-roman-9788249521562

  const subtitle = resten && `${resten?.charAt(0).toUpperCase()}${resten?.slice(1)}`;

  return {
    title,
    subtitle,
  };
};

const getDescription = (sortedPublications: CatmanduWork["publications"]): BibbiData["description"] =>
  sortedPublications.find((publication) => publication.description)?.description;

const getAbout = (sortedPublications: CatmanduWork["publications"]): BibbiData["about"] =>
  sortedPublications
    .find((publication) => !!publication.about?.length)
    ?.about?.map((autoritet) => autoritet.name?.["nob"] ?? autoritet.name?.["nno"] ?? "");

const getGenre = (sortedPublications: CatmanduWork["publications"]): BibbiData["genre"] =>
  sortedPublications
    .find((publication) => !!publication.genre?.length)
    ?.genre?.map((autoritet) => autoritet.name?.["nob"] ?? autoritet.name?.["nno"] ?? "");

const getCoverImage = (sortedPublications: CatmanduWork["publications"]): BibbiData["coverImage"] =>
  downscaleBibbiImage(sortedPublications.find((publication) => !!publication.image)?.image ?? undefined);

const getAudienceAges = (sortedPublications: CatmanduWork["publications"]): ForrigebokAudienceAges[] => {
  const audienceAges = sortedPublications
    .find((pub) => !!pub.audience?.length)
    ?.audience?.filter((it) => it.vocabulary === "nortarget")
    .filter((it) => it.group === "Aldersgruppe")
    .map((bibbiAudience) => bibbiAudienceToForrigebokAudienceMap[bibbiAudience.id] as ForrigebokAudienceAges)
    .filter(Boolean);

  return audienceAges?.length ? audienceAges : ["Voksne"]; // Fallbacker til Voksne dersom vi ikke har BMU-ing
};

const getPublication = (publication: CatmanduWork["publications"][number]): SanityKeyed<Publication> => {
  const { title, subtitle } = splitName(publication.name);
  return {
    id: publication.id,
    isbn: publication.isbn,
    title,
    subtitle,
    coverImage: downscaleBibbiImage(publication.image ?? undefined),
    datePublished: publication.datePublished,
    language: publication.language,
    type: publication["@type"],
    _type: "publication",
    _key: nanoid(),
  };
};

const getTags = (work: CatmanduWork | SimulatedCatmanduWork): ForrigebokWorkTags[] =>
  [!isSimulatedCatmanduWork(work) && !!work.kulturfond && "kulturfond"].filter(Boolean) as ForrigebokWorkTags[];

const sortPublications = (publications: CatmanduWork["publications"]) =>
  [...publications]
    // Rekkefølgen bestemmer prioriteringen. Siste .sort() veier tyngst.
    .sort(comparePublicationsBy((pub) => pub.isbn ?? 0)) // Ser ut som publikasjoner ikke kommer i stabil rekkefølge så det varierer hvilken som er først og blir brukt til bla cover-bilde. Sorterer derfor først på isbn for å få likt utgangspunkt hver gang
    .sort(comparePublicationsBy((pub) => pub.datePublished ?? 0))
    .sort(comparePublicationsBy((pub) => ["nno", "nob"].includes(pub.language)))
    .sort(comparePublicationsBy((pub) => pub["@type"] === "Book"));

const comparePublicationsBy =
  (comparator: (publication: CatmanduWork["publications"][number]) => string | boolean | number) =>
  (a: CatmanduWork["publications"][number], b: CatmanduWork["publications"][number]) => {
    const aValue = comparator(a);
    const bValue = comparator(b);
    if (aValue === bValue) return 0;
    return aValue > bValue ? -1 : 1;
  };

const downscaleBibbiImage = (imageUrl?: string) => {
  // Nedskalerer bilder fra bibbi. Bibbi returnerer orginal-oppløsning som defualt. Det fører til mange store bilder (noen over 1mB)
  if (imageUrl?.startsWith("https://media.aja.bs.no")) return imageUrl.replace("/original", "/thumbnail");
  return imageUrl;
};
