import { extendTheme } from "@biblioteksentralen/react";
import { Source_Sans_3 } from "next/font/google";

const sourceSans = Source_Sans_3({
  weight: ["400", "600", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const forrigebokTheme = extendTheme({
  fonts: {
    body: sourceSans.style.fontFamily,
    heading: sourceSans.style.fontFamily,
  },
  colors: {
    neptune: {
      "100": "#CFE6E8",
      "200": "#9FCDD2",
      "300": "#6FB5BB",
      "400": "#3F9CA5",
      "500": "#0F838E",
      "600": "#0C6972",
      "700": "#094F55",
      "800": "#063439",
      "900": "#031A1C",
    },
  },
});
