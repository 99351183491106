import { withErrorBoundary } from "@biblioteksentralen/react";
import Head from "next/head";
import React, { useEffect } from "react";

interface Props {
  title?: string;
  description?: string;
  path?: string;
}

const domain = "https://forrigebok.no";

function SEO(props: Props) {
  useEffect(() => {
    if (props.path && !/^\//.test(props.path)) {
      console.error(new Error("path må starte med /"));
    }
  }, [props.path]);

  const canonical = props.path ? `${domain}${props.path}` : null;
  const title = props.title ? `${props.title} | Forrigebok` : "Forrigebok";
  const description = props.description
    ? props.description
    : "Forrigebok er et digitalt verktøy for å jobbe med lesersørvis og registrering av appellfaktorer knyttet til bøker i norske folke- og skolebibliotek";
  const imageUrl = `${domain}/logo.png`;

  return (
    <Head>
      <title>{title}</title>
      {canonical && <link rel="canonical" href={canonical} />}
      <meta name="description" content={description} />
      <link rel="icon" type="image/png" href="/icon.png" />
      <meta property="image" content={imageUrl} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Forrigebok" />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Head>
  );
}

export default withErrorBoundary(SEO, "SEO");
