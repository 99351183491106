import { Stack } from "@biblioteksentralen/react";
import { Box, ChakraProps } from "@chakra-ui/react";
import { Bilde } from "@forrigebok/generatedtypes";
import { getImageDimensions } from "@sanity/asset-utils";
import Image from "next/image";
import { imageUrlBuilder } from "../utils/sanity";

interface Props {
  bilde: Bilde;
  width: number;
  hideCaption?: boolean;
}

function SanityImage({ bilde, hideCaption, width, ...chakraProps }: Props & ChakraProps) {
  const dimensions = bilde?.asset ? getImageDimensions(bilde) : { aspectRatio: 1 };
  const src = imageUrlBuilder(bilde)?.width(width).format("webp").quality(80).url() ?? "";
  const blurImageUrl = imageUrlBuilder(bilde)?.width(10).format("webp").quality(70).url();

  return (
    <Stack as="figure" spacing=".25em" {...chakraProps}>
      <Box borderRadius="lg" boxShadow="md" border="solid .1rem" borderColor="blackAlpha.100" overflow="hidden">
        <Image
          src={src}
          blurDataURL={blurImageUrl}
          placeholder="blur"
          alt={bilde?.altTekst ?? ""}
          width={width}
          height={Math.round(width / dimensions.aspectRatio)}
        />
      </Box>
      {!hideCaption && bilde?.caption && (
        <Box as="figcaption" opacity="0.75" fontSize=".9em" fontStyle="italic">
          {bilde.caption}
        </Box>
      )}
    </Stack>
  );
}

export default SanityImage;
