import { bmdbFetcher } from "@biblioteksentralen/bmdb-search-client";
import { BibbiData } from "@forrigebok/generatedtypes";
import { jsonFetcher } from "../fetchUtils";
import { BibbiPublicationsResponse } from "./BibbiPublication";
import { createSimulatedCatmanduWorkFromPublication } from "./SimulatedCatmanduWork";
import { createBibbiDataFromCatmandu } from "./createBibbiDataFromCatmandu";
import { createBibbiDataFromCordataWork } from "./createBibbiDataFromCordataWork";
import { normalizeIsbn } from "common/normalizeIsbn";

type Logger = (entry: string) => void;

// Tar imot både isbn og workId fordi vi må støtte registeringer på publikasjoner som ikke enda er verkifisert
export const getBibbiWork = async ({
  isbn,
  workId,
  appendLog,
}: {
  isbn?: string;
  workId?: string;
  appendLog?: Logger;
}): Promise<BibbiData | undefined> => {
  // Hvis vi har en verksid går vi rett på verksendepunktet
  if (workId) {
    const work = await getBibbiWorkFromId(workId, appendLog);
    return work;
  }

  if (isbn) {
    // Hvis vi ikke har verksid men isbn på en publikasjon sjekker vi først om publikasjonen har blitt verkifisert ved å søke på isbn i verksendepunktet
    const work = await getBibbiWorkFromIsbn(isbn, appendLog);
    if (work) return work;

    // Hvis ikke dette gir resultat, dvs at publikasjonen ikke er verkifisert, faller vi tilbake på publikasjonsendepunktet
    const simulatedWork = await getSimulatedBibbiWorkFromIsbn(isbn, appendLog);
    return simulatedWork;
  }

  throw new Error("Prøvde å fetche bibbiverk uten isbn eller verksid");
};

const getBibbiWorkFromId = async (workId: string, appendLog?: Logger) => {
  const data = await bmdbFetcher({
    catalogueId: "global",
    operation: "getWorks",
    clientIdentifier: "Forrigebok",
    params: { work_ids: [workId] },
  });

  const work = data?.works[workId];

  if (!work) {
    const message = `Kunne ikke fetche verk fra bibbi med id ${workId}`;
    appendLog?.(message);
    console.error(message, data);
    return undefined;
  }

  return createBibbiDataFromCordataWork(work);
};

const getBibbiWorkFromIsbn = async (isbn: string, appendLog?: Logger) => {
  const data = await bmdbFetcher({
    catalogueId: "global",
    operation: "searchWorks",
    clientIdentifier: "Forrigebok",
    params: { query: isbn },
  });

  const results = data?.results.map((result) => createBibbiDataFromCordataWork(result.work));

  const work = results?.find((result) =>
    result.publications?.some((publication) => normalizeIsbn(publication.isbn) === normalizeIsbn(isbn))
  );

  if (!work) {
    appendLog?.(`Fant ikke verk i bibbi som inneholder publikasjon med isbn ${isbn}`);
  }

  if ((data?.results.length ?? 0) > 1) {
    // Ikke sikkert denne publikasjonen er verkifisert enda, så kan ikke kaste feil bare fordi det ikke er noen treff
    const message = `Fant flere verk fra bibbi som inneholder publikasjon med isbn ${isbn}. For å koble til verk må dette gjøres manuelt. Verks-ider: ${results
      ?.map((work) => work.workId)
      .join(", ")}`;
    appendLog?.(message);
    console.error(message, data);
    return undefined;
  }

  return work;
};

const getSimulatedBibbiWorkFromIsbn = async (isbn: string, appendLog?: Logger) => {
  // TODO, denne bruker fortsatt gammelt endepunkt, bør byttes til data.bs.no om mulig
  const data = await jsonFetcher<BibbiPublicationsResponse>(
    `https://bibliografisk.bs.no/v1/publications?query=${encodeURIComponent(isbn)}`,
    { retries: 3 }
  );

  const publication = data.records[0];

  if (
    !publication ||
    data.total >
      1 /** TODO, skru på etter migrering (eksisterende samleverk gjør at denne krasjer) !allowedPublication(publication) */
  ) {
    const message = `Kunne ikke fetche publikasjon fra bibbi med isbn ${isbn}`;
    appendLog?.(message);
    console.error(message, data);
    return undefined;
  }

  const simulatedWork = createSimulatedCatmanduWorkFromPublication(publication);

  return createBibbiDataFromCatmandu(simulatedWork);
};
