import { useEffect, useState } from "react";
import { isProduction } from "./isProduction";

// Hook for at første render skal være lik SSR og CSR, hvis ikke kan det skape hydreringsproblemer med rare utfall i DOM'en
export const useFeauteToggleEnabled = (toggleKey: keyof typeof featureToggles) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    setEnabled(featureToggleEnabled(toggleKey));
  }, [toggleKey]);

  return enabled;
};

export const featureToggleEnabled = (toggleKey: keyof typeof featureToggles) => featureToggles[toggleKey]();

const featureToggles = {
  algoritme: () => queryParam("algoritme"),
  forfattersøk: () => queryParam("forfattersøk"),
  flereTermer: () => queryParam("flereTermer") || isEnv("development"),
  statistikk: () => queryParam("statistikk"),
  paginering: () => queryParam("paginering"),
};

type Environment = "development" | "staging" | "production";
const isDevelopment = process.env.NODE_ENV === "development";

/**
 * @param environments feks, isEnv('development', 'staging')
 */
export const isEnv = (...environments: Environment[]): boolean => {
  if (environments.includes("development") && isDevelopment) return true;
  if (environments.includes("production") && isProduction()) return true;
  if (environments.includes("staging") && !isDevelopment && !isProduction()) return true;
  return false;
};

/**
 * @param queries feks, queryParam('mintoggle'). Kan da skurs på ved å sende med queryparam i url "?ft=mintoggle"
 */
const queryParam = (...queries: string[]): boolean => {
  const flags = getEnabledFeatures();
  if (flags.includes("all")) return true; // Kan alltid skru på alle toggles ved å skrive ?ft=all i url
  if (queries.some((query) => flags.includes(query))) return true; // Kan sende med feks ?ft=statistikk i url
  return false;
};

const sessionStorageKey = "feature-toggles";

export const storeFeatureFlags = () => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return; // For SSR in nextjs

  const searchParams = new URLSearchParams(location.search);
  const flags = searchParams.getAll("ft");

  // setter bare feature-flag i localstorage hvis man faktisk sender inn feature-flags i url (uten denne sjekken vil eksisterende feature-flagg ble overskrevet ved reload)
  if (flags.length) {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(flags));
  }
};

export const getEnabledFeatures = (): string[] => {
  if (typeof window === "undefined" || typeof sessionStorage === "undefined") return []; // For SSR in nextjs

  const storedFlags = sessionStorage.getItem(sessionStorageKey);
  return storedFlags ? JSON.parse(storedFlags) : [];
};
