import { Flex, FlexProps, Link } from "@biblioteksentralen/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";

const localeToLabelMap = {
  nb: "Bokmål",
  nn: "Nynorsk",
};

export const ChangeLocale = (props: FlexProps & { linkProps?: React.ComponentProps<typeof Link> }) => {
  const { locales, locale, asPath } = useRouter();

  const otherLocales = locales?.filter((currentLocale) => currentLocale !== locale);

  return (
    <Flex gap=".5em" {...props}>
      {otherLocales?.map((locale) => (
        <Link key={locale} variant="plain" href={asPath} as={NextLink} locale={locale} {...props.linkProps}>
          {localeToLabelMap[locale] ?? locale}
        </Link>
      ))}
    </Flex>
  );
};
