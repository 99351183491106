import { Video } from "@forrigebok/generatedtypes";
import { Box, ChakraProps, colors, withErrorBoundary } from "@biblioteksentralen/react";
import styled from "@emotion/styled";
import * as React from "react";

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colors.neptune[600]};
`;

type Props = {
  video: Video;
} & ChakraProps;

function VimeoVideo({ video, ...chakraProps }: Props) {
  return (
    <Box paddingBottom="56.25%" position="relative" borderRadius="lg" overflow="hidden" {...chakraProps}>
      <StyledIframe
        src={`https://player.vimeo.com/video/${video.vimeoId}?h=5bd0f3232f&title=0&byline=0&portrait=0`}
        allowFullScreen
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
      />
    </Box>
  );
}

export default withErrorBoundary(VimeoVideo, "VimeoVideo");
