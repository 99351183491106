import Script from "next/script";
import React from "react";
import { isProduction } from "@forrigebok/common";
import { isDevelopment } from "@biblioteksentralen/utils";

export function PlausibleSetup() {
  if (!isProduction()) return null;
  return <Script defer data-domain={`forrigebok.no`} src="https://plausible.io/js/plausible.js" />;
}

type ExtraData = Record<string, string | number | boolean>;

// https://plausible.io/docs/custom-event-goals
function logCustomEvent(event: string, extraData?: ExtraData) {
  try {
    isDevelopment() && console.log("log", event, extraData);
    /** @ts-ignore */
    typeof plausible !== "undefined" && plausible(event, extraData ? { props: extraData } : undefined);
  } catch (e) {
    console.error(e);
  }
}

export function logClick(label: string, extraData: ExtraData = {}) {
  logCustomEvent("Click", { label, ...extraData });
}

export function logEvent(event: string, extraData: ExtraData = {}) {
  logCustomEvent("Event", { event, ...extraData });
}
