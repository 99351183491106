export { forrigebokAudienceAges, getVokabularFilter } from "./bibbi-integrasjoner/audienceAges";
export type { ForrigebokAudienceAges, RelevantForConfig } from "./bibbi-integrasjoner/audienceAges";
export type { BibbiPublication, BibbiPublicationsResponse } from "./bibbi-integrasjoner/BibbiPublication";
export type {
  CatmanduWork as BibbiWork,
  CatmanduWorksResponse as BibbiWorksResponse,
} from "./bibbi-integrasjoner/CatmanduWork";
export { createBibbiDataFromCatmandu } from "./bibbi-integrasjoner/createBibbiDataFromCatmandu";
export { getBibbiWork } from "./bibbi-integrasjoner/get";
export { bibbiSearch } from "./bibbi-integrasjoner/search";
export { isSimulatedCatmanduWork } from "./bibbi-integrasjoner/SimulatedCatmanduWork";
export type { SimulatedCatmanduWork as SimulatedBibbiWork } from "./bibbi-integrasjoner/SimulatedCatmanduWork";
export { erIllustrertVerk } from "./bibbi-integrasjoner/utils";
export { featureToggleEnabled, storeFeatureFlags, useFeauteToggleEnabled } from "./featureToggle";
export { jsonFetcher } from "./fetchUtils";
export { getVerkUrl } from "./getVerkUrl";
export { getSanityDataset, isProduction } from "./isProduction";
export { lagSammendrag } from "./lagSammendragAvRegistreringer";
export { slugifyString } from "./slugifyString";
export { uniqueStrings } from "./uniqueStrings";
export { updateSammendrag, type RegistreringerPåVerk } from "./updateSammendrag";
export { usePostRequest } from "./usePostRequest";
