import { Heading, VStack } from "@biblioteksentralen/react";
import { signIn } from "next-auth/react";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "src/utils/useTranslation";
import { velkomstQueryParam } from "./useVelkommenTilForrigebokToast";

const EpostBekreftet: FunctionComponent = () => {
  const { t } = useTranslation();
  useEffect(() => {
    signIn("auth0", {
      callbackUrl: `${window.location.protocol}//${window.location.host}/?${velkomstQueryParam}`,
    });
  }, []);

  return (
    <VStack>
      <Heading>{t("Et øyeblikk...")}</Heading>
    </VStack>
  );
};

export default EpostBekreftet;
